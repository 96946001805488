<template>
    <div class="main">
        <div class="main-width pt-5">
            <div class="alert alert-success text-center" role="alert" v-if="resultSuccess">
                {{ $t("invitations.success_message") }}
            </div>
            <div class="alert alert-warning text-center" role="alert" v-else>
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BacklinkInvitation",
    data() {
        return {
            resultSuccess: false,
            errorMessage: null
        };
    },
    mounted() {
        this.onShow();
    },
    methods: {
        onShow() {
            if (this.$route.params.id) {
                this.acceptInvitation(this.$route.params.id);
            }
        },
        acceptInvitation(id) {            
            this.$store.dispatch("SAVE_INVITATION", {id: id})
            .then(() => this.resultSuccess = true)
            .catch(e => e.data.message && (this.errorMessage = e.data.message))
            ;
        }
    }
}
</script>